import React from "react";
import { Helmet } from "react-helmet"
import {Layout} from '../components/Layout'
import {Link} from 'gatsby';

const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <title>Tüdel Beats - 404</title>
      </Helmet>

      <Layout>      
        <section className="section">
          <div className="container">
            <h1 className="title has-text-centered mb-6">404 - Not Found</h1>
            <p className="content has-text-centered my-6">Sorry, this page does not exist. Why not <Link to="/music">check out my music</Link>?</p>
          </div>
        </section>
      </Layout>
    </>
  )
}
export default NotFoundPage